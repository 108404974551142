// HreflangPage.tsx
import React, { useState, useEffect, useContext } from 'react';
import CodeMirror from '@uiw/react-codemirror';
import { html } from '@codemirror/lang-html';
import { material } from '@uiw/codemirror-theme-material';
import { doc, setDoc, getDoc, getDocs, collection, updateDoc, deleteDoc } from 'firebase/firestore';
import { dbHrefsets } from '../../App';
import { db } from '../../App';
import { UserContext } from '../../universal/context/UserContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';

import { useLoader } from '../../universal/context/LoaderContext';
import { useNotification } from '../../universal/context/NotificationContext';
import { getFunctions, httpsCallable } from 'firebase/functions';

interface IHreflang{
  hreflangSetName: string;
  hreflangSetId: string;
  hreflangSetValue: string; 
  hreflangSetDateUpdated: string;
  hreflangLastUpdatedBy: string;
  category: string;
}

const HreflangsPage = () => {
  // Add this in the component body
const { showNotification } = useNotification();
const { showLoader, hideLoader, updateProgress } = useLoader();
  //Shows Popup
  const [showMessage, setShowMessage] = useState<boolean>(false);
  //Popup Message
  const [popupMessage, setPopupMessage] = useState<string>('');
  
  //Show Confirmation Message
  const [showConfirmation, setShowConfirmation] = useState(false);
  //Get Confirmation Action
  const [confirmationActions, setConfirmationActions] = useState<{ handleConfirm: () => void; handleCancel: () => void }>({ handleConfirm: () => {}, handleCancel: () => {} });
  //Missing URL List
  const [missingURLs, setMissingURLs] = useState<string[]>([]);


  const [previousUrls, setPreviousUrls] = useState<string[]>([]);

  //Shows the Editing popup
  const [editingSet, setEditingSet] = useState<boolean>(false);
  //Edit Set Details
  const [editingHreflangSetName, setEditingHreflangSetName] = useState<string>('');
  const [editingHreflangSetId, setEditingHreflangSetId] = useState<string>('');
  const [editingHreflangSetValue, setEditingHreflangSetValue] = useState<string>('');
  const [editingHreflangSetDateUpdated, setEditingHreflangSetDateUpdated] = useState<Date>();

  const [preEditingPreHreflangSetName, setPreEditingHreflangSetName] = useState<string>('');
  const [preEditingPreHreflangSetId, setPreEditingHreflangSetId] = useState<string>('');
  const [preEditingPreHreflangSetValue, setPreEditingHreflangSetValue] = useState<string>('');
  const [preEditingSelectedCategory, setPreEditingSelectedCategory] = useState<string>('');


  //Stores available Categories
  const [categories, setCategories] = useState<{ id: string; name: string }[]>([]);
  //Sets the Selected Category
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  //Shows the Category Popup
  const [showNewCategoryPopup, setShowNewCategoryPopup] = useState<boolean>(false);
  //Sets the New Category Name
  const [newCategoryName, setNewCategoryName] = useState<string>('');

  //Category Filter
  const [categoryFilter, setCategoryFilter] = useState<string>('');

  //New Hreflang Set Details
  const [hreflangSets, setHreflangSets] = useState<IHreflang[]>([]); // Changed to array
  const [hreflangSetName, setHreflangSetName] = useState<string>('');
  const [hreflangSetId, setHreflangSetId] = useState<string>('');
  const [hreflangSetValue, setHreflangSetValue] = useState<string>('');
  const [hreflangLastUpdatedBy, setHreflangLastUpdatedBy] = useState<Date>();

  //Sets the active search Query
  const [searchQuery, setSearchQuery] = useState<string>('');
  //Shows the current filtered hreflangs
  let [filteredHreflangSet, setFilteredHreflangSet] = useState<IHreflang[]>([]); // Changed to array

  //Tracks the visibility of the Add Hreflang Set Form
  const [formVisible, setFormVisible] = useState<boolean>(false); // State to track visibility of the form
  const navigate = useNavigate();

  //Site Status Checker
  const [siteUpdateStatus, setSiteUpdateStatus] = useState<{ siteURL: string; status: 'pending' | 'success' | 'error' | 'requested'; message?: string }[]>([]);
  const [isUpdating, setIsUpdating] = useState<boolean>(false); // Track if an update is in progress
  const [isUpdateComplete, setIsUpdateComplete] = useState<boolean>(false);

  const [automaticExtractionEnabled, setAutomaticExtractionEnabled] = useState<boolean>(
    JSON.parse(localStorage.getItem('automaticExtractionEnabled') || 'true') // Default to true if not set
  );


  
  //Stores Details about User
  const { user } = useContext(UserContext);

  //Intial Calls
  useEffect(() => {
    //Check auths
    if (!user?.allowHreflangSetEditing) {
      navigate('/');
    }
  });
  
  useEffect(() => {
    initFetchHreflangs();
    fetchCategories();
  }, []);


  useEffect(() => {
    localStorage.setItem('automaticExtractionEnabled', JSON.stringify(automaticExtractionEnabled));
  }, [automaticExtractionEnabled]);

  //Fetches the Categories
  const fetchCategories = async () => {
    const categoriesCollectionRef = collection(dbHrefsets, 'categories');
    try {
      const querySnapshot = await getDocs(categoriesCollectionRef);
      const fetchedCategories = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data() as { name: string }
      }));
      setCategories(fetchedCategories);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
    hideLoader();
  };
  
  const fetchSiteList = async () => {
    try {
        const siteCollectionRef = collection(dbHrefsets, 'sites');
        const querySnapshot = await getDocs(siteCollectionRef);
        const sites = querySnapshot.docs.map(doc => doc.data() as { siteId: string, siteURL: string, category: string });
        return sites;
    } catch (error) {
        console.error('Error fetching site list:', error);
        return [];
    }
};


  //Inital Fetch of Hreflangs (without sorting)
  const initFetchHreflangs = async () => {
    showLoader();
    const hreflangsCollectionRef = collection(dbHrefsets, 'hreflangsets');
    try {
      const querySnapshot = await getDocs(hreflangsCollectionRef);
      const fetchedHreflangs = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data() as IHreflang
      }));
      setHreflangSets(fetchedHreflangs);
      setFilteredHreflangSet(fetchedHreflangs); // Initialize filtered hreflang sets
    } catch (error) {
      console.error("Error fetching hreflangs:", error);
    }
  };
  

  //Fetches the Hreflangs
  const fetchHreflangs = async () => {
    const hreflangsCollectionRef = collection(dbHrefsets, 'hreflangsets');
    try {
      const querySnapshot = await getDocs(hreflangsCollectionRef);
      const fetchedHreflangs = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data() as IHreflang
      }));
      setHreflangSets(fetchedHreflangs);
      handleFilter(searchQuery); // Apply filters to the fetched data
    } catch (error) {
      console.error("Error fetching hreflangs:", error);
    }
  };
  
  
  //Applies filters on changes
  useEffect(() => {
    applyFilters(searchQuery, categoryFilter);
  }, [hreflangSets, searchQuery, categoryFilter]);
  

  const updateHreflangSet = async () => {
    showLoader(`Checking compatibility `, 50);
  
    if (!editingHreflangSetName || !editingHreflangSetId || !selectedCategory) {
      showNotification('All fields must be filled out!', 'error');
      hideLoader();
      return;
    }
  
    const currentDate = new Date().toISOString();
  
    try {
      const hreflangsRef = doc(dbHrefsets, 'hreflangsets', editingHreflangSetId);
  
      if (preEditingPreHreflangSetId !== editingHreflangSetId) {
        // The ID has changed, create a new document and delete the old one
        const oldHreflangsRef = doc(dbHrefsets, 'hreflangsets', preEditingPreHreflangSetId);
  
        await setDoc(hreflangsRef, {
          hreflangSetName: editingHreflangSetName,
          hreflangSetId: editingHreflangSetId,
          hreflangSetValue: editingHreflangSetValue,
          hreflangSetDateUpdated: currentDate,
          hreflangLastUpdatedBy: user?.name,
          category: selectedCategory,
        });
  
        // Optionally delete the old document
        await deleteDoc(oldHreflangsRef);
      } else {
        // The ID hasn't changed, update the existing document
        await updateDoc(hreflangsRef, {
          hreflangSetName: editingHreflangSetName,
          hreflangSetValue: editingHreflangSetValue,
          hreflangSetDateUpdated: currentDate,
          hreflangLastUpdatedBy: user?.name,
          category: selectedCategory,
        });
      }
  
      // Handle URLs that have been removed
      const currentUrls = extractUrlsFromHreflangSetValue(editingHreflangSetValue);
      const urlsToDelete = previousUrls.filter(url => !currentUrls.includes(url));
  
      if (urlsToDelete.length > 0) {
        // Proceed to delete the hreflang set from the removed URLs
        const token = process.env.REACT_APP_BEARER_TOKEN;
        const sites = await fetchSiteList();
        setSiteUpdateStatus(urlsToDelete.map(url => ({ siteURL: url, status: 'pending' }))); // Initialize status
  
        try {
          // Iterate over urlsToDelete to send delete requests
          const deletePromises = urlsToDelete.map(async (url) => {
            const site = sites.find((site) => url.startsWith(site.siteURL));
            if (site) {
              const response = await fetch(`${site.siteURL}wp-json/hreflang/v1/deleteHrefset`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ id: editingHreflangSetId }),
              });
  
              // Update status
              setSiteUpdateStatus((prevStatus) =>
                prevStatus.map((s) =>
                  s.siteURL === url
                    ? {
                        ...s,
                        status: response.ok ? 'success' : 'error',
                        message: response.ok ? 'Deleted successfully' : `Error: ${response.statusText || 'Unknown error'}`,
                      }
                    : s
                )
              );
  
              if (!response.ok) {
                throw new Error(`Failed to delete on site ${site.siteURL}: ${response.statusText}`);
              }
            } else {
              // Update status when no matching site is found
              setSiteUpdateStatus((prevStatus) =>
                prevStatus.map((s) =>
                  s.siteURL === url
                    ? {
                        ...s,
                        status: 'error',
                        message: 'No matching site found',
                      }
                    : s
                )
              );
            }
          });
  
          await Promise.all(deletePromises);
  
          showNotification('Removed hreflang set from removed URLs successfully!', 'success');
        } catch (error) {
          console.error('Error deleting hreflang set from some URLs:', error);
          showNotification('Failed to delete hreflang set from some URLs!', 'error');
        }
      }
  
      // Proceed with sending the updated hreflang set to the remaining URLs
      hideLoader();
      await fetchHreflangs();
      setEditingSet(false);
      await sendHreflangSetToWordPress(editingHreflangSetId, editingHreflangSetValue);
  
    } catch (error) {
      showNotification('Error accessing the database: ' + error, 'error');
    }
  
    setEditingHreflangSetName("");
    setEditingHreflangSetId("");
    setSelectedCategory('');
    setEditingHreflangSetValue("");
    hideLoader();
  };
  
  
  



  const sendHreflangSetToWordPress = async (id: string, value: string) => {
    const urls = extractUrlsFromHreflangSetValue(value); // Get unique hreflang URLs
    const sites = await fetchSiteList();
    const token = process.env.REACT_APP_BEARER_TOKEN;

    // Initialize UI update states
    setIsUpdating(true);
    setSiteUpdateStatus(urls.map(url => ({ siteURL: url, status: 'pending' }))); // Track each unique URL

    console.log("Found URLs:", urls); // Log all extracted URLs

    try {
        // Process URLs in batches of 10
        for (let i = 0; i < urls.length; i += 10) {
            const batch = urls.slice(i, i + 10); // Get a batch of 10 URLs

            console.log("Processing batch:", batch); // Log each batch of URLs

            // Set status to "Requested" for URLs in the current batch
            setSiteUpdateStatus((prevStatus) =>
                prevStatus.map((s) =>
                    batch.includes(s.siteURL)
                        ? { ...s, status: 'requested', message: 'Request initiated' }
                        : s
                )
            );

            const batchPromises = batch.map(async (url) => {
                // Extract domain and path from the URL
                const parsedUrl = new URL(url);
                const domain = parsedUrl.hostname;
                const path = parsedUrl.pathname.endsWith('/') ? parsedUrl.pathname : `${parsedUrl.pathname}/`;

                // Find the correct site that matches both domain and path exactly
                const site = sites.find(site => {
                  const bestMatchUrl = findBestMatchUrl(url, sites.map(site => site.siteURL));
                  return bestMatchUrl === site.siteURL;
              });
              

                console.log("URL:", url, "matched site:", site ? site.siteURL : "No match found"); // Log the matched site or no match

                if (site) {
                    const response = await fetch(`${site.siteURL}wp-json/hreflang/v1/update`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                        body: JSON.stringify({ id, hreflangSet: value }),
                    });

                    // Convert the response to JSON and extract debug info
                    const responseData = await response.json();

                    // Update UI with the result and include debug info in the message
                    setSiteUpdateStatus((prevStatus) =>
                        prevStatus.map((s) =>
                            s.siteURL === url
                                ? {
                                    ...s,
                                    status: response.ok ? 'success' : 'error',
                                    message: 'Successfully Updated'/*`Debug Info: ${JSON.stringify(responseData.debug || 'No debug info')}`*/,
                                }
                                : s
                        )
                    );
                } else {
                    // If no matching site found for the URL
                    setSiteUpdateStatus((prevStatus) =>
                        prevStatus.map((s) =>
                            s.siteURL === url
                                ? {
                                    ...s,
                                    status: 'error',
                                    message: 'No matching site found',
                                }
                                : s
                        )
                    );
                }
            });

            // Wait for all promises in the current batch to resolve before moving to the next batch
            await Promise.all(batchPromises);
        }

        showNotification('Hreflang set processed successfully!', 'success');
    } catch (error) {
        // Explicitly handle 'unknown' error type
        if (error instanceof Error) {
            console.error('Error sending hreflang set:', error.message);
            showNotification(`Failed to send hreflang set! ${error.message}`, 'error');
        } else {
            console.error('Unexpected error:', error);
            showNotification('An unexpected error occurred while sending hreflang set!', 'error');
        }
    } finally {
        setIsUpdating(true); // Reset the updating state
        hideLoader();
    }
};



const findBestMatchUrl = (url: string, siteURLs: string[]): string | undefined => {
  // Sort the URLs by length in descending order to prioritize longer matches
  const sortedSiteURLs = siteURLs.sort((a, b) => b.length - a.length);
  
  // Iterate over sorted site URLs and find the first match
  for (const siteURL of sortedSiteURLs) {
      if (url.startsWith(siteURL)) {
          return siteURL; // Return the first (longest) match found
      }
  }
  
  return undefined; // Return undefined if no match is found
};


  
  
  
  
  //Extracts URLS
 // Extracts unique URLs
const extractUrlsFromHreflangSetValue = (value: string): string[] => {
  const urlRegex = /href="(.*?)"/g;
  const uniqueUrls = new Set<string>(); // Use Set to store unique URLs
  let match;
  while ((match = urlRegex.exec(value)) !== null) {
    uniqueUrls.add(match[1]); // Automatically handles duplicates
  }
  return Array.from(uniqueUrls); // Convert back to array
};

  
  //Fetches all the URLs from DB
  const fetchSiteURLs = async () => {
    const sitesCollectionRef = collection(dbHrefsets, 'sites');
    const querySnapshot = await getDocs(sitesCollectionRef);
    const siteURLs = querySnapshot.docs.map(doc => doc.data().siteURL);
    return siteURLs;
  };

  //Show Confirmation Popup
  const showConfirmationDialog = (missingURLs: string[]): Promise<boolean> => {
    return new Promise((resolve) => {
      setShowConfirmation(true);
      setMissingURLs(missingURLs);
  
      const handleConfirm = () => {
        setShowConfirmation(false);
        resolve(true);
      };
  
      const handleCancel = () => {
        setShowConfirmation(false);
        resolve(false);
      };
  
      setConfirmationActions({ handleConfirm, handleCancel });
    });
  };
  
  

  const createHreflangSet = async () => {
    showLoader();
  
    if (!hreflangSetName) {
      showNotification('Name has not been set!', 'error');
      hideLoader();
      return;
    }
    if (!hreflangSetId) {
      showNotification('Set ID has not been set!', 'error');
      hideLoader();
      return;
    }
    if (!selectedCategory) {
      showNotification('Category has not been selected!', 'error');
      hideLoader();
      return;
    }
    if (!hreflangSetValue) {
      showNotification('Value has not been set!', 'error');
      hideLoader();
      return;
    }
  
    const hreflangsRef = doc(dbHrefsets, 'hreflangsets', hreflangSetId);
    const urls = extractUrlsFromHreflangSetValue(hreflangSetValue);
  
    try {
      const siteURLs = await fetchSiteURLs();
      const missingURLs = urls.filter(url => !siteURLs.some(siteURL => url.startsWith(siteURL)));
      console.log("URLS TOTAL: " + missingURLs);
  
      if (missingURLs.length > 0) {
        const proceed = await showConfirmationDialog(missingURLs);
  
        if (!proceed) {
          hideLoader();
          return;
        }
      }
      const docSnapshot = await getDoc(hreflangsRef);
      if (docSnapshot.exists()) {
        // Document already exists, so don't override it.
        setHreflangSetId('');
        showNotification('A set with this ID already exists!', 'error');
      } else {
        const currentDate = new Date().toISOString(); // Get the current date as an ISO string
        await setDoc(hreflangsRef, {
          hreflangSetName: hreflangSetName,
          hreflangSetId: hreflangSetId,
          hreflangSetValue: hreflangSetValue,
          hreflangSetDateUpdated: currentDate, // Store the current date as an ISO string
          hreflangLastUpdatedBy: user?.name,
          category: selectedCategory
        });
  
        hideLoader();
      setEditingSet(false);

        await sendHreflangSetToWordPress(hreflangSetId, hreflangSetValue); // Add this line to send the hreflang set to WordPress
  
        setHreflangSetId('');
        setHreflangSetName('');
        setHreflangSetValue('');
        setSelectedCategory('');
        showNotification('Hreflang Set Created Successfully!', 'success');
        await fetchHreflangs(); // Fetch hreflangs and wait for the data to be updated
      }
    } catch (error) {
      console.error("Error accessing the database:", error);
      showNotification('Failed to create or check hreflang set.', 'error');
    }
    hideLoader();
  };
  
  
  
  

  const handleEditorChange = async (value: string) => {
  setHreflangSetValue(value);

  // Fetch all sites with their URLs and categories
  const sites = await fetchSiteList(); // Assuming fetchSiteList() fetches all sites with URLs and categories

  if (automaticExtractionEnabled) {
    // Extract the first URL from the value
    const urls = extractUrlsFromHreflangSetValue(value);
    if (urls.length > 0) {
      const firstUrl = new URL(urls[0]);

      // Get the hostname (e.g., 'example' from 'https://example.com')
      const domainParts = firstUrl.hostname.split('.');
      const firstPart = domainParts[0]; // This is the 'example' part

      // Find the matching site by comparing the extracted URL with the site's URL
      const matchedSite = sites.find(site => {
        const siteUrl = new URL(site.siteURL);
        return firstUrl.hostname === siteUrl.hostname;
      });

      // Set the category if a matching site is found
      let categoryName = '';
      if (matchedSite && matchedSite.category) {
        categoryName = matchedSite.category;
        setSelectedCategory(categoryName);
      } else {
        setSelectedCategory(''); // Reset if no matching category is found
      }

      // Get the path from the URL to use as a slug
      const slug = firstUrl.pathname.replace(/^\/|\/$/g, ''); // Remove leading/trailing slashes

      // Automatically set the name and ID
      if (slug) {
        // Format the category for the ID
        const formattedCategoryForId = categoryName.toLowerCase().replace(/\s+/g, '-'); // Replace spaces with hyphens
        const formattedName = `${categoryName} ${slug.replace(/-/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase())}`;
        setHreflangSetName(formattedName);

        const formattedId = `${formattedCategoryForId}-${slug.replace(/\s+/g, '-').toLowerCase()}`;
        setHreflangSetId(formattedId);
      }
    }
  }
};


  const handleAutomaticExtractionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAutomaticExtractionEnabled(e.target.checked);
  };
  
  const handleEditingEditorChange = (value: string) => {
    setEditingHreflangSetValue(value);
  };
  const toggleFormVisibility = () => {
    setFormVisible(!formVisible);
  };

  const handleEditSet = (hreflangSet: IHreflang) => {
    
    setPreEditingHreflangSetName(hreflangSet.hreflangSetName);
    setPreEditingHreflangSetId(hreflangSet.hreflangSetId);
    setPreEditingHreflangSetValue(hreflangSet.hreflangSetValue);
    setPreEditingSelectedCategory(hreflangSet.category);
  
    // Setting previous URLs
    setPreviousUrls(extractUrlsFromHreflangSetValue(hreflangSet.hreflangSetValue));
  
    setEditingHreflangSetName(hreflangSet.hreflangSetName);
    setEditingHreflangSetId(hreflangSet.hreflangSetId);
    setEditingHreflangSetValue(hreflangSet.hreflangSetValue);
    setSelectedCategory(hreflangSet.category);
    setEditingSet(true);
  };
  
  const cancelUpdatingHreflangSet = () => {
    setEditingHreflangSetName("");
    setEditingHreflangSetId("");
    setPreviousUrls([]);
    setEditingHreflangSetValue("");
    
    setPreEditingHreflangSetName("");
    setPreEditingHreflangSetId("");
    setPreEditingHreflangSetValue("");
    setEditingHreflangSetDateUpdated(new Date);
    setEditingSet(false);
  }
  const handleHidePopupMessage = () => {
    setShowMessage(false)
  }

  const handleFilter = (query: string) => {
  setSearchQuery(query);
  applyFilters(query, categoryFilter);
};

const handleCategoryFilterChange = (category: string) => {
  setCategoryFilter(category);
  applyFilters(searchQuery, category);
};

const applyFilters = (query: string, category: string) => {
  let filtered = hreflangSets;

  if (query.trim() !== '') {
    filtered = filtered.filter(
      (set) =>
        set.hreflangSetName.toLowerCase().includes(query.toLowerCase()) ||
        set.hreflangSetId.toLowerCase().includes(query.toLowerCase())
    );
  }

  if (category) {
    filtered = filtered.filter((set) => set.category === category);
  }

  setFilteredHreflangSet(filtered);
};


  //Formats the date in a readable format
  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  
  // Function to delete hreflang set
  const deleteHreflangSet = async (id: string) => {
    showLoader();
    const token = process.env.REACT_APP_BEARER_TOKEN;
  
    // Fetch the hreflang set to get the value
    const hreflangsRef = doc(dbHrefsets, 'hreflangsets', id);
    const docSnapshot = await getDoc(hreflangsRef);
  
    if (!docSnapshot.exists()) {
      showNotification('Hreflang set not found!', 'error');
      hideLoader();
      return;
    }
  
    const hreflangSet = docSnapshot.data() as IHreflang;
    const urls = extractUrlsFromHreflangSetValue(hreflangSet.hreflangSetValue); // Extract URLs from hreflang value
    const sites = await fetchSiteList(); // Fetch list of sites
  
    setSiteUpdateStatus(urls.map(url => ({ siteURL: url, status: 'pending' }))); // Initialize status
  
    try {
      // Iterate over extracted URLs to send delete requests
      const deletePromises = urls.map(async (url) => {
        const site = sites.find((site) => url.startsWith(site.siteURL));
        if (site) {
          const response = await fetch(`${site.siteURL}/wp-json/hreflang/v1/deleteHrefset`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({ id }),
          });
  
          // Update status based on response
          setSiteUpdateStatus((prevStatus) =>
            prevStatus.map((s) =>
              s.siteURL === url
                ? {
                    ...s,
                    status: response.ok ? 'success' : 'error',
                    message: response.ok ? 'Deleted successfully' : `Error: ${response.statusText || 'Unknown error'}`,
                  }
                : s
            )
          );
  
          // If the response is not ok, throw an error to catch block
          if (!response.ok) {
            throw new Error(`Failed to delete on site ${site.siteURL}: ${response.statusText}`);
          }
        } else {
          // Update status when no matching site is found
          setSiteUpdateStatus((prevStatus) =>
            prevStatus.map((s) =>
              s.siteURL === url
                ? {
                    ...s,
                    status: 'error',
                    message: 'No matching site found',
                  }
                : s
            )
          );
        }
      });
  
      // Wait for all delete requests to complete
      await Promise.all(deletePromises);
  
      // Proceed to delete from Firebase if all WordPress deletions succeeded
      await deleteDoc(hreflangsRef); // Delete from Firebase
      showNotification('Hreflang set deleted successfully!', 'success');
  
      await fetchHreflangs(); // Refresh the list after deletion
    } catch (error) {
      console.error('Error deleting hreflang set:', error);
      showNotification('Failed to delete hreflang set!', 'error');
    } finally {
      hideLoader();
    }
  };
  

// Updated delete button handler
const handleDelete = (hreflangSetId: string) => {
  if (window.confirm('Are you sure you want to delete this Hreflang Set?')) {
    deleteHreflangSet(hreflangSetId);
  }
};



  const handleShowNewCategoryPopup = () => {
    setShowNewCategoryPopup(true);
  };
  
  const handleHideNewCategoryPopup = () => {
    setShowNewCategoryPopup(false);
    setNewCategoryName('');
    setSelectedCategory('');
  };
  
  const handleAddCategory = async () => {
    if (!newCategoryName.trim()) {
      showNotification('Category name cannot be empty!', 'error');
      return;
    }
  
    showLoader();
    const newCategoryRef = doc(dbHrefsets, 'categories', newCategoryName.trim());
  
    try {
      const docSnapshot = await getDoc(newCategoryRef);
      if (docSnapshot.exists()) {
        setNewCategoryName('');
        showNotification('Category ' + newCategoryName.trim() + ' already exists!', 'error');
      }
      else{
        await setDoc(newCategoryRef, { name: newCategoryName.trim() });
        setCategories([...categories, { id: newCategoryName.trim(), name: newCategoryName.trim() }]);
        setNewCategoryName('');
        setShowNewCategoryPopup(false);
        showNotification('Category ' + newCategoryName.trim() + ' added successfully!', 'success');
      }
    } catch (error) {
      showNotification('Failed to add category!', 'error');
    }
  
    hideLoader();
  };
  
  //Category grouping
  const groupByCategory = (hreflangs: IHreflang[]) => {
    return hreflangs.reduce((groups: { [key: string]: IHreflang[] }, hreflang: IHreflang) => {
      const category = hreflang.category || 'Uncategorized';
      if (!groups[category]) {
        groups[category] = [];
      }
      groups[category].push(hreflang);
      return groups;
    }, {});
  };
  
  const groupedHreflangs = groupByCategory(filteredHreflangSet);
  
  return (
    <div className='dashpanel'>
    <div className="main">
      <div className="head">
        {showMessage && (
          <div className="popup-overlay">
            <div className="delete-confirmation-popup">
              <p><b>{popupMessage}</b></p>
              <button className='sitemanagerbutton selected' onClick={() => handleHidePopupMessage()}>OKAY</button>
            </div>
          </div>
        )}
        {showConfirmation && (
          <div className="edit-overlay z-ind-25">
            <div className="delete-confirmation-popup">
              <div className="add-client-form edit-hreflang">
              <h3>⚠️ ATTENTION ⚠️</h3>
                <p style={{fontWeight:'bolder'}}>The following URLs do not have a site added in the <u><a href='/userapp/sitelister' target={'_blank'} className='usertext'>Site Lister</a></u> and will <u>NOT</u> be updated in the website.</p>
                <div style={{marginTop:'10px',marginBottom:'10px'}}>
                  {missingURLs.map((url, index) => (
                    <p style={{fontSize:'12px',fontWeight:'400'}} key={index}>{url}</p>
                  ))}
                  </div>
                  <p style={{fontWeight:'bolder'}}>Would you still like to proceed?</p>
                <div className='buttonsetcontainer hrefpopup'>
                  <button style={{width:150}} className='sitemanagerbutton addhref chunkpadded' onClick={confirmationActions.handleConfirm}>PROCEED</button>
                  <button style={{width:100}} className='sitemanagerbutton deletehref chunkpadded' onClick={confirmationActions.handleCancel}>CANCEL</button>
                </div>
              </div>
            </div>
          </div>
        )}
        {isUpdating && (
  <div className="edit-overlay z-ind-25">
    <div className="delete-confirmation-popup">
      <div style={{ alignItems: "center", display: "flex", flexDirection: "column" }} className="add-client-form edit-hreflang">
        <h3>Updating Hreflang Sets</h3>
        {/* Make the list scrollable by wrapping it in a div */}
        <div className="hreflang-update-status-list">
          {siteUpdateStatus.map((site) => (
            <li key={site.siteURL} className={`status-item ${site.status}`}>
              <span className="hreflang-url">{site.siteURL}</span>
              <span className="status-icon">
                {site.status === 'pending' && '⏳ Processing...'}
                {site.status === 'success' && '✅ Success'}
                {site.status === 'error' && '❌ Failed'}
              </span>
              {site.message && <span className="status-message"> - {site.message}</span>}
            </li>
          ))}
        </div>
        {/* Close button to manually close the popup */}
        <button style={{ width: "fit-content" }} className='close-button sitemanagerbutton' onClick={() => setIsUpdating(false)}>
          Close
        </button>
      </div>
    </div>
  </div>
)}




        {showNewCategoryPopup && (
          <div className="edit-overlay z-ind-25">
            <div className="delete-confirmation-popup">
              <div className="add-client-form edit-hreflang">
                <h3>Add New Category</h3>
                <label>Category Name</label>
                <input
                  type="text"
                  value={newCategoryName}
                  onChange={(e) => setNewCategoryName(e.target.value)}
                  placeholder="New Category Name"
                />
                <div className='buttonsetcontainer hrefpopup'>
                  <button style={{width:150}} className='sitemanagerbutton addhref chunkpadded' onClick={handleAddCategory}>Add Category</button>
                  <button style={{width:150}} className='sitemanagerbutton deletehref chunkpadded' onClick={handleHideNewCategoryPopup}>Cancel</button>
                </div>
              </div>
            </div>
          </div>
        )}
        {editingSet && (
          <div className="edit-overlay">
            <div className="delete-confirmation-popup">
              <div className="add-client-form edit-hreflang">
                <h3>Edit Hreflang Set: {editingHreflangSetName}</h3>
                <label>Hreflang Set Name</label>
                <input type="text" value={editingHreflangSetName} onChange={(e) => setEditingHreflangSetName(e.target.value)} placeholder="Hreflang Set Name" />
                <label>Hreflang Set ID</label>
                <input type="text" value={editingHreflangSetId} onChange={(e) => setEditingHreflangSetId(e.target.value)} placeholder="hreflang-unique-id" />
                <label>Category</label>
              <div className='flatcat'>
              <select
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
              >
                <option value="">Select a category</option>
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
              <div className='plus' onClick={handleShowNewCategoryPopup}>+</div>
              </div>
                <label>Hreflang HTML Code</label>
                <CodeMirror
                  value={editingHreflangSetValue}
                  height="350px"
                  theme={material}
                  extensions={[html()]}
                  onChange={handleEditingEditorChange}
                />
                <div className='buttonsetcontainer hrefpopup'>
                  <button style={{width:150}} className='sitemanagerbutton addhref chunkpadded' onClick={updateHreflangSet}>Update Hreflang Set</button>
                  <button style={{width:100}} className='sitemanagerbutton deletehref chunkpadded' onClick={cancelUpdatingHreflangSet}>Cancel</button>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className='warning-text'>Remember to add new websites to the site lister by clicking in the navbar or <a href="/userapp/sitelister">clicking here</a></div>
        <h1>Hreflangs Manager</h1>
        <h2>
          Add and edit <span className="usertext">Hreflang Sets</span>.
        </h2>
        <button className={formVisible ? 'hiddenbuttonstlye' : 'termsbuttonstyleadd'} onClick={toggleFormVisibility}>
          {formVisible ? 'Hide Hreflang Set Adder' : 'Show Hreflang Set Adder'}
        </button>
        {(formVisible) && (
          <>
            <div className="add-client-form">
              <h3>Create Hreflang Set</h3>
              {/* Add Automatic Extraction Checkbox */}
        <div style={{ marginBottom: '15px' }}>
          <label>
            <input
              type="checkbox"
              checked={automaticExtractionEnabled}
              onChange={handleAutomaticExtractionChange}
            />
            Enable Automatic Extraction
          </label>
        </div>
              <label>Hreflang Set Name</label>
              <input type="text" value={hreflangSetName} onChange={(e) => setHreflangSetName(e.target.value)} placeholder="Hreflang Set Name" />
              <label>Hreflang Set ID</label>
              <input type="text" value={hreflangSetId} onChange={(e) => setHreflangSetId(e.target.value)} placeholder="hreflang-unique-id" />
              <label>Category</label>
              <div className='flatcat'>
              <select
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
              >
                <option value="">Select a category</option>
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
              <div className='plus' onClick={handleShowNewCategoryPopup}>+</div>
              </div>
              <label>Hreflang HTML Code</label>
              <CodeMirror
                value={hreflangSetValue}
                height="350px"
                theme={material}
                extensions={[html()]}
                onChange={handleEditorChange}
              />
              <button style={{}} className='sitemanagerbutton empty' onClick={createHreflangSet}>Create Hreflang Set</button>
            </div>
          </>
        )}
        {!formVisible &&(
          <div style={{width:350,padding:5,display:'flex'}} className="add-client-form edit-hreflang">
          <input style={{marginBottom:0}} type="text" value={searchQuery} onChange={(e) => handleFilter(e.target.value)} placeholder="Search Hreflang Sets" />
          <select style={{marginBottom:0,marginLeft:5}} value={categoryFilter} onChange={(e) => handleCategoryFilterChange(e.target.value)}>
            <option value="">All Categories</option>
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
          <div className='flatcat'>
          <div style={{marginBottom:0}} className='plus' onClick={handleShowNewCategoryPopup}>+</div>
          </div>
          </div>
        )}
        {!formVisible && (
          <div className='catblockcontainer'>
           {Object.entries(groupedHreflangs).map(([category, hreflangs]: [string, IHreflang[]]) => (
              <div className='catblock' key={category}>
                <h2 className='categorytitle'>{category}</h2>
                <div className='bubble-container'>
                {hreflangs.map((hreflang: IHreflang) => (
                  
                  <div key={hreflang.hreflangSetId} className='bubble hrefsets'>
                    <h2>{hreflang.hreflangSetName}</h2>
                    <h3>{hreflang.hreflangSetId}</h3>
                    <p style={{marginTop:3}} className='hreflastupdated'><FontAwesomeIcon style={{fontSize:'15px'}} className='listed icon' icon={['fas', 'person']}/> <b className="usertext supbold">{hreflang.hreflangLastUpdatedBy}</b></p>
                    <p className='hreflastupdated'><FontAwesomeIcon style={{fontSize:'10px'}} className='listed icon' icon={['fas', 'clock']}/> {formatDate(hreflang.hreflangSetDateUpdated)}</p>
                    <div className='buttonsetcontainer'>
                      <button className='sitemanagerbutton selectedhref' onClick={() => handleEditSet(hreflang)}>Edit</button>
                      <button className='sitemanagerbutton deletehref' onClick={() => handleDelete(hreflang.hreflangSetId)}>Delete</button>
                    </div>
                  </div>
                  
                ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
    </div>
  );
  
};

export default HreflangsPage;
